import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../model/api-response';
import {
    deleteCareerUrl,
    deleteRoleUrl, editRoleUrl,
    getAllCareerRequest,
    getAllRoleDataUrl,
    getAllRolesUrl,
    getPermissionUrl,
    saveRoleUrl
} from '../model/endpoints';
import {Role} from '../model/role';

@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:class-name
export class RoleService {

    constructor(private http: HttpClient) {}

    saveRole(role: Role) {
        // @ts-ignore
        return this.http.post<ApiResponse>(saveRoleUrl, role, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }

    getAllRoles() {
        // @ts-ignore
        return this.http.get<ApiResponse>(getAllRolesUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }

    getPermissions(roleName: string) {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(getPermissionUrl + roleName, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }

    getAllRoleTableData() {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(getAllRoleDataUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }

    deleteRole(id: number) {
        // @ts-ignore
        return this.http.get<ApiResponse>(deleteRoleUrl + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }

    editRole(role: Role) {
        // @ts-ignore
        return this.http.post<ApiResponse>(editRoleUrl, role, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
    }
}
