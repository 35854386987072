export const prodUrl = 'https://api1.cagliero.eu/';
// export const prodUrl = 'http://localhost:8085/';
// export const documentUrl = 'http://localhost:8087/';
export const documentUrl = prodUrl + 'docs/';
export const authUrl = prodUrl + 'user/login';
export const getAllUsersUrl = prodUrl + 'user/getAll';
export const getAllDemoUrl = prodUrl + 'demo/getAll?page=';
export const getAllRequestUrl = prodUrl + 'service/getAll';
export const getAllFeedbacksUrl = prodUrl + 'feedback/getAll';
export const getUserCountUrl = prodUrl + 'user/count';
export const getFeedbackCountUrl = prodUrl + 'feedback/count';
export const getDemoCountUrl = prodUrl + 'demo/count';
export const getAllCareerRequest = prodUrl + 'career/gelAll';
export const demoAccessUrl = prodUrl + 'demo/access';
export const changePasswordUrl = prodUrl + 'user/changePassword';
export const updateUserProfileUrl = prodUrl + 'user/update';
export const createUserUrl = prodUrl + 'user/register';
export const getAccessDemoUrl = prodUrl + 'demo/access/all';
export const getCompleteDemoUrl = prodUrl + 'demo/complete/all';
export const getStatsUrl = prodUrl + 'dashboard/stats';
export const deleteUser = prodUrl + 'user/delete?id=';
export const getUser = prodUrl + 'user/get?id=';
export const deleteRequestDemo = prodUrl + 'demo/delete?id=';
export const deleteServiceUrl = prodUrl + 'service/delete?id=';
export const deleteFeedbackUrl = prodUrl + 'feedback/delete?id=';
export const deleteCareerUrl = prodUrl + 'career/delete?id=';
export const deleteAccessDemo = prodUrl + 'demo/access/delete?id=';
export const forgotPassword = prodUrl + 'user/forgot?username=';
export const validateOtp = prodUrl + 'validate/otp?otp=';
export const getAllQuotationUrl = documentUrl + 'quotation/getAll';
export const deleteQuotationUrl = documentUrl + 'quotation/delete?id=';
export const saveQuotationUrl = documentUrl + 'quotation/save';
export const downloadQuotationUrl = documentUrl + 'quotation/download?id=';
export const saveRoleUrl = prodUrl + 'role/save';
export const getAllRolesUrl = prodUrl + 'role/getAll';
export const getPermissionUrl = prodUrl + 'role/permissions?roleName=';
export const getAllRoleDataUrl = prodUrl + 'role/getTableData';
export const deleteRoleUrl = prodUrl + 'role/delete?id=';
export const editRoleUrl = prodUrl + 'role/edit';
