import { Component, OnInit } from '@angular/core';
// @ts-ignore
import {User} from '../../../model/user';
import {MatDialog, MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../service/user-service';
import {ChangePasswordDto} from '../../../model/changePasswordDto';

@Component({
  selector: 'app-tbl-basic',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  user: User;
  data: ChangePasswordDto = new ChangePasswordDto();
  changePasswordForm: FormGroup;

  constructor(private snackBar: MatSnackBar, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.changePasswordForm = new FormGroup({
      password: new FormControl(undefined, Validators.required),
      repeat: new FormControl(undefined, Validators.required),
      old: new FormControl(undefined, Validators.required)
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000
    });
  }

  changePassword() {
    this.openSnackBar('Please Wait...');
    if(this.changePasswordForm.value.password === this.changePasswordForm.value.repeat){
      this.data.username = this.user.username;
      this.data.oldPassword = this.changePasswordForm.value.old;
      this.data.password = this.changePasswordForm.value.password;
      this.data.reset = false;
      this.userService.changePassword(this.data).subscribe(response => {
        if (response.status === 1) {
          this.openSnackBar(response.message);
          this.dialog.closeAll();
        } else {
          this.openSnackBar('Your request can\'t be completed right now.');
        }
      }, error => {
        console.log(error);
        this.openSnackBar('Your request can\'t be completed right now.');
      });
    } else {
      this.openSnackBar('Two Passwords are not matching');
    }
  }
}
