import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../../../../model/User';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ChangePasswordComponent} from '../../../../../home module/dashboard/change-password/change-password.component';
import {UserProfileComponent} from '../../../../../home module/dashboard/user-profile/user-profile.component';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
    name: string;
    user: User;

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.name = this.user.name;
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['auth/signin']);
  }

  openChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '650px',
      backdropClass: 'th-dialog-bg',
      panelClass: 'th-dialog-panel',
      disableClose: true
    });
  }

  openProfile() {
    const dialogRef = this.dialog.open(UserProfileComponent, {
      width: '650px',
      backdropClass: 'th-dialog-bg',
      panelClass: 'th-dialog-panel',
      disableClose: true
    });
  }
}
