export class User {
    id: number;
    name: string;
    type: string;
    username: string;
    password: string;
    email: string;
    phoneNumber: string;
    role: string;
    userStatus: string;
    created: string;
}
