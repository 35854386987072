import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../model/api-response';
import {Authentication} from '../model/authentication';
import {
  authUrl,
  changePasswordUrl,
  createUserUrl, deleteUser, forgotPassword,
  getAllUsersUrl,
  getStatsUrl, getUser,
  getUserCountUrl,
  updateUserProfileUrl, validateOtp
} from '../model/endpoints';
import {ChangePasswordDto} from '../model/changePasswordDto';
import {User} from '../model/User';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:class-name
export class UserService {

  constructor(private http: HttpClient) {}

  loginUser(auth: Authentication) {
    return this.http.post<ApiResponse>(authUrl, auth);
  }

  getAllUsers() {
    // @ts-ignore
    return this.http.get<ApiResponse>(getAllUsersUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  getUserCount() {
    // @ts-ignore
    return this.http.get<ApiResponse>(getUserCountUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  changePassword(data: ChangePasswordDto){
    return this.http.post<ApiResponse>(changePasswordUrl, data);
  }

  updateUser(data: User) {
    return this.http.post<ApiResponse>(updateUserProfileUrl, data, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  signUp(user: User) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<ApiResponse>(createUserUrl, user, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  getStats() {
    return this.http.get<ApiResponse>(getStatsUrl, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  deleteUser(id: any) {
    return this.http.get<ApiResponse>(deleteUser + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  getUser(id: any) {
    return this.http.get<ApiResponse>(getUser + id, {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('caglieroToken'))});
  }

  forgotUser(username: any) {
    return this.http.get<ApiResponse>(forgotPassword + username);
  }

  validateOtp(username: string, otp: string) {
    return this.http.get<ApiResponse>(validateOtp + otp + '&username=' + username);
  }
}
